<template>
  <div>
    <a-breadcrumb class="bread-crumb">
      <a-breadcrumb-item> {{ trafficPoolName }}</a-breadcrumb-item>
    </a-breadcrumb>

    <a-tabs :active-key="activeKey" @change="changeTab">
      <a-tab-pane
        v-for="item in tabs"
        :key="item.routeName"
        :tab="item.tabName"
      >
        <router-view v-if="activeKey===item.routeName" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findTrafficPoolName } from '@/api/traffic_pool'

export default {
  name: 'TrafficPoolShowTab',
  data() {
    return {
      activeKey: '',
      trafficPoolName: '',
      tabs: [
        { routeName: 'agency_traffic_pool_info', tabName: '流量池信息概览' },
        { routeName: 'agency_traffic_pool_sim_cards', tabName: '流量池卡号列表' },
        { routeName: 'agency_traffic_pool_expand_records', tabName: '流量池扩容记录' },
        { routeName: 'agency_traffic_pool_usage_orders', tabName: '财务及流量信息' }
      ]
    }
  },
  computed: {
    trafficPoolId() {
      return parseInt(this.$route.params.traffic_pool_id)
    }
  },
  watch: {
    trafficPoolId() {
      this.setBreadcrumb()
    }
  },
  created() {
    this.activeKey = this.$route.name
    this.setBreadcrumb()
  },
  methods: {
    setBreadcrumb() {
      findTrafficPoolName(this.trafficPoolId).then((res) => {
        if (res.code === 0) {
          this.trafficPoolName = res.data.name
        }
      })
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    changeTab(activeKey) {
      this.activeKey = activeKey
      this.$router.push({ name: activeKey })
    }
  }
}
</script>

<style lang="less" scoped>
.bread-crumb {
  margin-bottom: 10px;
}
</style>

